import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w11GB02Plätzchen = () => (
  <Layout>
    <SEO title="w11GB02Plätzchen" />

    <h1 id="ueberschrift">Gemeinsam Backen</h1>
    <h2>Weihnachtszeit = Plätzchenzeit?! &emsp;  ©&nbsp;2003</h2>

<p className="note">!!!DIESER KURS WIRD AKTUELL AUSGESETZT!!!</p>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w11GB02Plätzchen&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Alles macht viel mehr Spaß, wenn es zusammen mit anderen erforscht und gekostet werden kann.
        Und noch schöner ist es in der stressigen Weihnachtszeit "einfach mal herunter zu fahren" und
        gemeinsam mit anderen in der Küche Ihrer Wahl verschiedene Plätzchenrezepte auszuwählen.
        Genießen Sie die Zeit
        beim geselligen Zubereiten, Verzieren und Probekosten der Kunstwerke, welche oft
        mit lustigen und interessanten Anekdoten gewürzt sind.
      </p>
      <p>
        Auf Grund der aktuellen Situation kann dieser Kurs nur "außer Haus" angeboten werden.
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Mitmachkurs
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitz- oder Stehplatz pro Teilnehmer + mind. 1 Sitz- oder Stehplatz extra<br/>
        Materialbedarf: + je nach Gericht +
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze und etwas Freiraum
      </p>
      <p>
        Voraussetzung: + Grundkenntnisse im Umgang mit diversen Küchenutensilien +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w11GB02Plätzchen
